import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import DefaultLink from '../components/DefaultLink'
import DifferenceObject from '../components/DifferenceObject'
import { main } from '../lib/colors'

const Main = styled.section`
  width: 100%;
  height: 100%;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`
const Title = styled.h1`
  display: inline-block;
  align-self: center;
  margin-bottom: 10px;
  margin-top: 0px;

  span {
    color: ${main.highlight};
  }
`

const Phrase = styled.span`
  display: inline-block;
  align-self: center;
`

const NotFoundPage = () => (
  <>
    <Layout theme={main}>
      <Main>
        <Title>Oops, nothing here</Title>
        <Phrase>
          Are you lost?{' '}
          <DefaultLink to="./" as={Link}>
            Start here
          </DefaultLink>
          .
        </Phrase>
        <DifferenceObject />
      </Main>
    </Layout>
  </>
)

export default NotFoundPage
